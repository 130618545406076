'use client'

import React from 'react';
import { motion } from 'framer-motion';
import frame5 from '../images/Frame 5.png';

const links = [
  { name: 'Careers', href: '#' },
  { name: 'Newsroom ', href: '#' },
 
  { name: 'LinkedIn', href: '#' },
];

const stats = [
  { name: 'Founded in  ', value: '2025' },
  { name: 'Merchants', value: '123' },
  { name: 'Users', value: '456' },
  { name: 'Monthly Volume  ', value: '789' },
];

const containerVariants = {
  hidden: { opacity: 0 },
  visible: { 
    opacity: 1,
    transition: { 
      staggerChildren: 0.1,
      delayChildren: 0.3
    }
  }
};

const itemVariants = {
  hidden: { y: 20, opacity: 0 },
  visible: { 
    y: 0, 
    opacity: 1,
    transition: { type: 'spring', stiffness: 100 }
  }
};

export default function Company() {
  return (
    <motion.div 
      className="relative isolate overflow-hidden bg-gray-900 min-h-screen" // Removed outer padding
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      {/* Fullscreen Background Image */}
      <div className="absolute inset-0 -z-10">
        <img
          src={frame5}
          alt="Frame 5"
          className="object-cover w-full h-full opacity-15 blur-l"
        />
      </div>

      {/* Content Wrapper with Padding */}
      <div className="relative z-10 flex flex-col items-start text-left px-4 py-24 sm:py-32 sm:px-6 lg:px-8 mx-auto max-w-7xl">
        <motion.div className="max-w-2xl" variants={itemVariants}>
          <h2 className="text-5xl font-semibold tracking-tight text-white sm:text-4xl"> Payments</h2>
          <p className="mt-8 text-lg font-medium text-gray-300 sm:text-xl/8">
            Anim aute id magna aliqua ad ad non deserunt .
         
          </p>
          <p className="mt-8 text-lg font-medium text-gray-300 sm:text-xl/8">
            Anim aute id magna aliqua ad ad non deserunt .
         
          </p>
          <p className="mt-8 text-lg font-medium text-gray-300 sm:text-xl/8">
            Anim aute id magna aliqua ad ad non deserunt .
         
          </p>
        </motion.div>

        <div className="mt-10 max-w-2xl lg:max-w-none">
          <motion.div 
            className="grid grid-cols-1 gap-y-6 text-base/7 font-semibold text-white text-left sm:grid-cols-2 md:flex lg:gap-x-10"
            variants={itemVariants}
          >
            {links.map((link) => (
              <motion.a 
                key={link.name} 
                href={link.href} 
                whileHover={{ scale: 1.05 }} 
                whileTap={{ scale: 0.95 }}
                className="sm:ml-0"
              >
                {link.name} <span aria-hidden="true">&rarr;</span>
              </motion.a>
            ))}
          </motion.div>

          <motion.dl 
            className="mt-16 grid grid-cols-1 gap-8 text-left sm:mt-20 sm:grid-cols-2 lg:grid-cols-4"
            variants={itemVariants}
          >
            {stats.map((stat) => (
              <motion.div 
                key={stat.name} 
                className="flex flex-col-reverse gap-1 " 
                variants={itemVariants}
              >
                <dt className="text-base/7 text-gray-300">{stat.name}</dt>
                <dd className="text-4xl font-semibold tracking-tight text-white">{stat.value}</dd>
              </motion.div>
            ))}
          </motion.dl>
        </div>
      </div>
    </motion.div>
  );
}
