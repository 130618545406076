import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className='bg-gray-100 text-gray-900 min-h-screen flex flex-col'>
      <div className='max-w-7xl mx-auto px-8 sm:px-8 lg:px-16 py-10'>
        <h1 className='text-2xl font-bold mb-6'>Privacy Policy</h1>
        <p className='mb-6'>
        This privacy policy governs the manner in which we (Acount) uses, maintains and discloses information collected from users (each, a "User").
        This privacy policy applies to the application and all products and services offered by us and personal data we collect from you when you use our website.
        </p>

      
        <h2 className='text-xl font-semibold mb-4'>Topics:
        </h2>
        <p className='mb-6'>
        - What data do we collect?
        <br></br>
        - How do we collect your data?
        <br></br>
        - How will we use your data?
        <br></br>
        - How do we store your data?
        <br></br>
        - What are your data protection rights?
        <br></br>
        - Privacy policy of other websites
        <br></br>
        - Changes to our privacy policy
        <br></br>
        - How to contact us
        </p>


        <h2 className='text-xl font-semibold mb-4'>What data do we collect?</h2>
        <p className='mb-6'>
        Acount collects the following data: Personal and business identification information (Business email address, company name, full name and company website)        </p>


        <h2 className='text-xl font-semibold mb-4'>How do we collect your data?</h2>
        <p className='mb-6'>
        You directly provide Acount with all of the data we collect. We collect data and process data when you: Voluntarily request access to any of our products or services through our website via our email.        </p>


        <h2 className='text-xl font-semibold mb-4'>How will we use your data?</h2>
        <p className='mb-6'>
        Acount collects your data so that we can: Process your access request via email correspondence. 
        </p>


        <h2 className='text-xl font-semibold mb-4'>How do we store your data?</h2>
        <p className='mb-6'>
        Acount securely stores your data at AWS EU Frankfurt Region (eu-central-1) data center with SOC 2, HIPAA compliance. All customer data is encrypted at rest with AES-256 and in transit via TLS.

        </p>


        <h2 className='text-xl font-semibold mb-4'>What are your data protection rights?</h2>
        <p className='mb-6'>
        Acount would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
        <br></br><br></br>
        The right to access – You have the right to request Acount for copies of your personal data.
        <br></br><br></br>
        The right to rectification – You have the right to request that Acount correct any information you believe is inaccurate. You also have the right to request Acount to complete the information you believe is incomplete.
        <br></br><br></br>
        The right to erasure – You have the right to request that Acount erase your personal data, under certain conditions.
        <br></br><br></br>
        The right to restrict processing – You have the right to request that Acount restrict the processing of your personal data, under certain conditions.
        <br></br><br></br>
        The right to object to processing – You have the right to object to Acount processing of your personal data, under certain conditions.
        <br></br><br></br>
        The right to data portability – You have the right to request that Acount transfer the data that we have collected to another organisation, or directly to you, under certain conditions.
        <br></br><br></br>
        If you make a request, we have three months to respond to you. If you would like to exercise any of these rights, please contact us at our email:
        <br></br><br></br>
        info@withacount.com

        </p>


        <h2 className='text-xl font-semibold mb-4'>Privacy policies of other websites</h2>
        <p className='mb-6'>
        The Acount website contains links to other websites. Our privacy policy applies only 
        to our website, so if you click on a link to another website, you should read their 
        privacy policy.

        </p>


        <h2 className='text-xl font-semibold mb-4'>Changes to our privacy policy</h2>
        <p className='mb-6'>
        Acount keeps its privacy policy under regular review and places any updates on 
        this web page. This privacy policy was last updated on 14 June 2024.

        </p>


        <h2 className='text-xl font-semibold mb-4'>How to contact us</h2>
        <p className='mb-6'>
        If you have any questions about Acount’s privacy policy, the data 
        we hold on you, or you would like to exercise one of your data protection 
        rights, please do not hesitate to contact us at our email: 
        <br></br> <br></br> info@withacount.com

        </p>

      </div>
    </div>
  );
};

export default PrivacyPolicy;
