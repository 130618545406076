'use client'

import React from 'react';
import { ChevronDown } from 'lucide-react';

const faqs = [
  {
    category: 'Getting Started',
    subtopics: [
      { subtopic: 'Sign Up', description: 'How to create an account.' },
      { subtopic: 'Pricing Structure', description: 'Details of pricing tiers.' },
      { subtopic: 'Payment Methods', description: 'Supported payment options.' },
      { subtopic: 'Account Verification', description: 'How to verify your account.' },
      { subtopic: 'Platform Overview', description: 'Introduction to platform features.' },
    ],
  },
  {
    category: 'Account Management',
    subtopics: [
      { subtopic: 'Password Reset', description: 'Steps to reset your password.' },
      { subtopic: 'Email Change', description: 'How to update your email address.' },
      { subtopic: 'Profile Settings', description: 'Edit your account details.' },
      { subtopic: 'Two-Factor Authentication', description: 'How to enable 2FA.' },
      { subtopic: 'Account Deletion', description: 'How to close your account.' },
    ],
  },
  {
    category: 'Technical Support',
    subtopics: [
      { subtopic: 'Bug Reporting', description: 'How to report bugs or issues.' },
      { subtopic: 'API Access', description: 'How to access API documentation.' },
      { subtopic: 'Troubleshooting', description: 'Steps to solve common issues.' },
      { subtopic: 'System Maintenance', description: 'Scheduled maintenance updates.' },
      { subtopic: 'Feature Requests', description: 'How to submit feature requests.' },
    ],
  },
  {
    category: 'Advanced Features',
    subtopics: [
      { subtopic: 'Custom Integrations', description: 'How to integrate with third-party services.' },
      { subtopic: 'Automation', description: 'Automating your workflow on the platform.' },
      { subtopic: 'Advanced Settings', description: 'Exploring advanced platform settings.' },
    ],
  },

  {
    category: 'Advanced Features',
    subtopics: [
      { subtopic: 'Custom Integrations', description: 'How to integrate with third-party services.' },
      { subtopic: 'Automation', description: 'Automating your workflow on the platform.' },
      { subtopic: 'Advanced Settings', description: 'Exploring advanced platform settings.' },
    ],
  },

  {
    category: 'Advanced Features',
    subtopics: [
      { subtopic: 'Custom Integrations', description: 'How to integrate with third-party services.' },
      { subtopic: 'Automation', description: 'Automating your workflow on the platform.' },
      { subtopic: 'Advanced Settings', description: 'Exploring advanced platform settings.' },
    ],
  },
];

export default function Resources() {
  return (
    <div className="bg-gray-50 min-h-screen">
      {/* Header Section */}
      <header className="bg-white shadow">
        <div className="mx-auto max-w-7xl px-6 py-16 sm:py-20 lg:px-8 ">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900">How can we help you?</h1>
          <p1> abc </p1>
        </div>
      </header>

      {/* FAQ Categories Section */}
      <main className="mx-auto max-w-7xl px-6 py-12 lg:px-8">
        <div className="grid grid-cols-1 gap-y-12 lg:grid-cols-3 lg:gap-x-8">
          {faqs.map((faq) => (
            <section key={faq.category} className="flex flex-col space-y-6">
              <h2 className="text-xl font-semibold text-gray-800">{faq.category}</h2>
              <div className="space-y-4">
                {faq.subtopics.map((subtopic, index) => (
                  <div key={index} className="border-b border-gray-200 pb-4">
                    <details className="group">
                      <summary className="flex justify-between items-center cursor-pointer text-gray-700 group-open:text-indigo-600">
                        <span>{subtopic.subtopic}</span>
                        <ChevronDown className="ml-2 text-gray-400 group-open:text-indigo-600" size={16} />
                      </summary>
                      <p className="mt-2 text-sm text-gray-500">{subtopic.description}</p>
                    </details>
                  </div>
                ))}
              </div>
            </section>
          ))}
        </div>
      </main>

      {/* Footer Section */}
      <footer className="bg-gray-50 py-6 mt-12">
        <div className="mx-auto max-w-7xl px-6 lg:px-8 text-center">
          <p className="text-sm text-gray-500">Need further assistance? <a href="#" className="text-indigo-600 hover:underline">Contact us</a>.</p>
        </div>
      </footer>
    </div>
  );
}
