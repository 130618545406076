import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import jyske from '../images/jyske.png';
import mitid from '../images/Mitid.png';
import danske from '../images/danske.png';
import nordea from '../images/nordea.png';

const LogoPlaceholder1 = () => (
  <img 
    src={jyske}
    alt="Jyske Logo" 
    className="w-full h-full object-contain object-center"
    style={{ 
      border: 'none', 
      outline: 'none', 
      maxWidth: '120px',
      maxHeight: '80px'
    }}
  />
);

const LogoPlaceholder2 = () => (
  <img 
    src={nordea}
    alt="nordea Logo" 
    className="w-full h-full object-contain object-center"
    style={{ 
      border: 'none', 
      outline: 'none', 
      maxWidth: '120px',
      maxHeight: '80px'
    }}
  />
);

const LogoPlaceholder3 = () => (
  <img 
    src={danske}
    alt="danske Logo" 
    className="w-full h-full object-contain object-center"
    style={{ 
      border: 'none', 
      outline: 'none', 
      maxWidth: '120px',
      maxHeight: '80px'
    }}
  />
);
const FlippingButton = () => {
  const [currentSide, setCurrentSide] = useState(0);

  const sides = [
    { content: <LogoPlaceholder1 /> },
    { content: <LogoPlaceholder2 /> },
    { content: <LogoPlaceholder3 /> }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSide((prev) => (prev + 1) % sides.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <motion.div
      key={currentSide}
      initial={{ rotateX: 90 }}
      animate={{ rotateX: 0 }}
      exit={{ rotateX: -90 }}
      transition={{ duration: 0.5 }}
      className="flex items-center justify-center" // Removed padding and border
      style={{
        minWidth: '120px',
        minHeight: '80px',
      }}
    >
      {sides[currentSide].content}
    </motion.div>
  );
};

const Footer = () => {
  return (
    <footer className="w-full bg-[#ffffff] py-12">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-5 gap-12">
          <div className="lg:col-span-2 space-y-8">
            <div className="flex justify-start">
              <span className="text-sm font-bold text-gray-900">
                Get started with apay today. To advance a more secure, <br />
                efficient and integrated online interbank payments network.
              </span>
            </div>

            <div className="space-y-4">
              <div className="flex items-center gap-1"> {/* Reduced gap to 1 */}
                <FlippingButton />
                <motion.button
                  className="rounded-md bg-[#0060E6] p-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#0050C2] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#0060E6] flex items-center justify-center"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <img
                    src={mitid}
                    alt="Mitid Logo"
                    className="w-5 h-5 object-contain"
                  />
                </motion.button>
              </div>
              <p className="text-sm font-bold text-gray-600 text-left">Powered by aPay</p>
            </div>
          </div>

          <div className="lg:col-start-4 space-y-6">
            <h4 className="text-lg font-medium text-gray-900">Home</h4>
            <ul className="space-y-4">
              <li><Link to="/faqs" className="text-sm text-gray-600 hover:text-gray-900">Business</Link></li>
              <li><Link to="/quick-start" className="text-sm text-gray-600 hover:text-gray-900">Individuals</Link></li>
              <li><Link to="/documentation" className="text-sm text-gray-600 hover:text-gray-900">Company</Link></li>
              <li><Link to="/user-guide" className="text-sm text-gray-600 hover:text-gray-900">Resources</Link></li>
            </ul>
          </div>

          <div className="lg:col-start-5 space-y-6">
            <h4 className="text-lg font-medium text-gray-900">Legal</h4>
            <ul className="space-y-4">
              <li><Link to="/news" className="text-sm text-gray-600 hover:text-gray-900">End User Terms</Link></li>
              <li><Link to="/tips-tricks" className="text-sm text-gray-600 hover:text-gray-900">Revoke AIS Consent</Link></li>
              <li><Link to="/tips-tricks" className="text-sm text-gray-600 hover:text-gray-900">Privacy Policy</Link></li>
              <li><Link to="/tips-tricks" className="text-sm text-gray-600 hover:text-gray-900">Contact us</Link></li>
            </ul>
          </div>
        </div>
        <div className="mt-12 text-xs py-8 text-gray-400">
          © 2025 APAY Technologies ApS. All rights reserved. CVR 12345678. Tuborg Havnevej 16, 2900 Hellerup, Denmark.
          Registered at Payment services provided by Payment Institution regulated by the German Financial Supervisory Authority (BAFIN) with identification.
          APay is a technology company, not a licensed financial institution. All names are trademarks™ or registered trademarks® of their respective holders.
          Their usage does not imply any affiliation with or endorsement by their holders.
        </div>
      </div>
    </footer>
  );
}

export default Footer;
